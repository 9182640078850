import {Injectable} from "@angular/core";
import { Subject } from "rxjs";
import {BreakpointObserver} from "@angular/cdk/layout";

export enum ScreenSize {
  Mobile = "(max-width: 767.98px)",
  Tablet = "(min-width: 768px) and (max-width: 1439.98px)",
  Desktop = "(min-width: 1440px) and (max-width: 1919.98px)",
  LargeDesktop = "(min-width: 1920px)"
}

const Screens = [ScreenSize.Mobile, ScreenSize.Tablet, ScreenSize.Desktop, ScreenSize.LargeDesktop];

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private openLoaderCount: number = 0;

    loadState = new Subject<boolean>();
    screenSize = new Subject<ScreenSize>();
    screenSizeValue: ScreenSize = ScreenSize.Desktop;
    observableLoadState = this.loadState.asObservable();
    observableScreenSize = this.screenSize.asObservable();

    get currentSize() {
      return this.screenSizeValue;
    }

    constructor(private responsive: BreakpointObserver) {
      responsive.observe(Screens).subscribe(res => {
        for(let i of Screens) {
          if(res.breakpoints[i]) {
            this.screenSizeValue = i;
            this.screenSize.next(i);
            return;
          }
        }

        this.screenSize.next(ScreenSize.Desktop);
      })
    }

    openLoader(){
        this.openLoaderCount++;
        setTimeout(() => {
          if(this.openLoaderCount >= 1){
            this.loadState.next(true);
          }
        }, 275);
    }

    closeLoader(){
        this.openLoaderCount--;
        setTimeout(() => {
          if(this.openLoaderCount <= 0){
            this.loadState.next(false);
          }
        }, 370);
    }

    setRedirectUrl(url: string){
        if(!url) {
            localStorage.removeItem('redirectUrl');
        } else {
            localStorage.setItem('redirectUrl', url);
        }
    }

    get redirectUrl() {
        return localStorage.getItem('redirectUrl');
    }
}
