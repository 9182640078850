import {afterNextRender, Component, inject} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {commonModuleImports} from "../../app.imports";
import {MenuItem, UserAccessService} from "../../services/auth.service";
import {FooterComponent} from "../footer/footer.component";
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {User, UserData} from '../../abstract/users';
import {LoadingService, ScreenSize} from "../../services/loading.service";

@Component({
  imports: [...commonModuleImports, FooterComponent],
  selector: 'ngx-personal-area-common',
  styleUrls: ['./personal-area.component.scss'],
  templateUrl: './personal-area.component.html',
  standalone: true
})
export class PersonalAreaComponent {
  private readonly oidcSecurityService = inject(OidcSecurityService);

  user!: User;
  userId: string | null = null;

  get linkPrefix() {
    return this.accessService && this.hasAccess('admin') && this.userId
      ? '/awesome-admin/' + this.userId
      : '';
  }

  menu:MenuItem[] = [];

  bottomMenu = [
    {
      name: 'Settings',
      icon: '/assets/images/settings.svg',
      role: 'user',
      link: '/my/settings',
      action: () => this.redirectToSettings(),
      class: ''
    },
    {
      name: 'Log Out',
      icon: '/assets/images/log-out.svg',
      role: 'user',
      link: '/',
      action: () => this.logout(),
      class: ''
    }
  ]

  combinedMenu: any[] = [];

  get currentSize() {
    return this.loadingService.currentSize;
  }

  get showSidebarMenu() {
    return this.currentSize == ScreenSize.Desktop || this.currentSize == ScreenSize.LargeDesktop;
  }

  constructor(
    private router: Router,
    private readonly route: ActivatedRoute,
    private readonly accessService: UserAccessService,
    private readonly loadingService: LoadingService,
    private userService: UserData
  ) {
    afterNextRender(()=> {
      this.route.paramMap.subscribe(params => {
        this.userId = params.get('userId');
        const newMenu = this.getMenu();
        if(this.userId) {
          newMenu.splice(3, 0, {
            name: 'Bots',
            icon: '/assets/images/spend.svg',
            role: 'admin',
            link: `${this.linkPrefix}/my/bots`,
            class: ''
          })
        }
        if(JSON.stringify(newMenu) !== JSON.stringify(this.menu))
        {
          this.menu = newMenu;
          this.accessService.setMenu(this.menu);
        }
      })
      this.oidcSecurityService
        .checkAuth()
        .subscribe((loginResponse: LoginResponse) => {
          const { isAuthenticated, userData, accessToken, idToken, configId } =
              loginResponse;
          if(isAuthenticated) {
            this.userService.getMyUser()
                .subscribe(res => {
                  this.accessService.setUser(res);
                  this.user = res;
                });
          } else {
            this.accessService.setUser(null);
          }
        });
    });
  }

  getMenu(): MenuItem[] {
    return [
      {
        name: 'Overview',
        icon: '/assets/images/overview.svg',
        role: 'user',
        link: `${this.linkPrefix}/my`,
        class: ''
      },
      {
        name: 'Referral Program',
        icon: '/assets/images/referral-program.svg',
        role: 'user',
        link: `${this.linkPrefix}/my/referral-program`,
        class: ''
      },
      {
        name: 'Payments',
        icon: '/assets/images/payments.svg',
        role: 'user',
        link: `${this.linkPrefix}/my/payments`,
        class: ''
      },
      {
        name: 'Administration',
        icon: '/assets/images/referral-program.svg',
        role: 'admin',
        link: `/awesome-admin/users`,
        class: ''
      }
    ]
  }

  hasAccess(role: string) {
    return this.accessService.isInRole(role);
  }

  isActive(link: string): boolean {
    return this.router.url === link;
  }

  logout() {
    this.accessService.getUser();
    this.oidcSecurityService.logoff().subscribe({
      next: () => {
        this.accessService.setUser(null);

        localStorage.clear();
        sessionStorage.clear();

        window.location.href = '/';
      },
      error: (err) => console.error('Logout error:', err)
    });
  }

  redirectToSettings(){
    this.router.navigate(['/my/settings']);
  }
}
