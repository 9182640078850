@if(!isGreat()){
  <div class="certain-action">
    <span class="back-panel-btn" (click)="sendResult(false)">
      <img ngSrc="/assets/images/Mask.svg" alt="arrow-r" width="16" height="14">
    </span>

    <h4>{{ title() }}</h4>

    <div class="action-btn">
      <button class="red" (click)="sendResult(true)">
        <span><img ngSrc="/assets/images/red_stop_btn.svg" alt="red_stop_btn" width="24" height="24"></span>
        Stop Bot
      </button>
      <button class="contact" onclick="window.open('https://t.me/Burvix_support', '_blank')">
        <span><img ngSrc="/assets/images/phone.svg" alt="phone" width="24" height="25"></span>
        Contact Us
      </button>
    </div>
  </div>
} @else {
  <div class="certain-action great">
    <span class="back-panel-btn" (click)="sendResult(false)">
      <img ngSrc="/assets/images/Mask.svg" alt="arrow-r" width="16" height="14">
    </span>

    <h4>The bot will be launched automatically within 30 minutes. Thank you for your trust!</h4>

    <div class="action-btn">
      <button class="green">
        <span class="box"><img ngSrc="/assets/images/great.svg" alt="great" width="24" height="25"></span>
        Great!
      </button>
      <button class="contact" onclick="window.open('https://t.me/Burvix_support', '_blank')">
        <span><img ngSrc="/assets/images/phone.svg" alt="phone" width="24" height="25"></span>
        Contact Us
      </button>
    </div>
  </div>
}
