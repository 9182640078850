<div class="pagination">
  <div class="pagination-inner" *ngIf="pagesCount > 1">
    <span (click)="loadPrevPage()">
      <svg [ngClass]="{ 'nonactive': currentPage === 1 }" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 5.99991H3.135L6.768 1.63991C7.122 1.21591 7.064 0.584909 6.64 0.231909C6.215 -0.122091 5.585 -0.0640909 5.232 0.359909L0.232 6.35991C0.193 6.40691 0.173 6.46191 0.144 6.51391C0.12 6.55591 0.091 6.59191 0.073 6.63791C0.028 6.75291 0.001 6.87391 0.001 6.99591C0.001 6.99691 0 6.99891 0 6.99991C0 7.00091 0.001 7.00291 0.001 7.00391C0.001 7.12591 0.028 7.24691 0.073 7.36191C0.091 7.40791 0.12 7.44391 0.144 7.48591C0.173 7.53791 0.193 7.59291 0.232 7.63991L5.232 13.6399C5.43 13.8769 5.714 13.9999 6 13.9999C6.226 13.9999 6.453 13.9239 6.64 13.7679C7.064 13.4149 7.122 12.7839 6.768 12.3599L3.135 7.99991H15C15.552 7.99991 16 7.55191 16 6.99991C16 6.44791 15.552 5.99991 15 5.99991Z" fill="white"/>
      </svg>        
    </span>
    <div class="pagination-num">
      @for (page of pages; track $index) {
        <p [ngClass]="{ 'active': currentPage.toString() === page}" (click)="selectPage(page)">{{page}}</p>
      }
    </div>
    <span class="rigth" (click)="loadNextPage()">
      <svg [ngClass]="{ 'nonactive': currentPage === pagesCount }" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 5.99991H3.135L6.768 1.63991C7.122 1.21591 7.064 0.584909 6.64 0.231909C6.215 -0.122091 5.585 -0.0640909 5.232 0.359909L0.232 6.35991C0.193 6.40691 0.173 6.46191 0.144 6.51391C0.12 6.55591 0.091 6.59191 0.073 6.63791C0.028 6.75291 0.001 6.87391 0.001 6.99591C0.001 6.99691 0 6.99891 0 6.99991C0 7.00091 0.001 7.00291 0.001 7.00391C0.001 7.12591 0.028 7.24691 0.073 7.36191C0.091 7.40791 0.12 7.44391 0.144 7.48591C0.173 7.53791 0.193 7.59291 0.232 7.63991L5.232 13.6399C5.43 13.8769 5.714 13.9999 6 13.9999C6.226 13.9999 6.453 13.9239 6.64 13.7679C7.064 13.4149 7.122 12.7839 6.768 12.3599L3.135 7.99991H15C15.552 7.99991 16 7.55191 16 6.99991C16 6.44791 15.552 5.99991 15 5.99991Z" fill="white"/>
      </svg> 
    </span>
  </div>
</div>
