<div class="dropdown" [class.panel]="isPanel()">
    <button class="dropdown-button" (click)="toggleDropdown()">
      {{ getTitle(selectedOption || '') }}
      <div class="polygon-block">
        <img class="polygon" src="/assets/images/Polygon 1.svg" alt="Polygon">
      </div>
    </button>
    <div class="block-list" [class.show]="dropdownOpen">
      <ul class="dropdown-list">
        @for (option of options(); track option) {
            <li (click)="selectOption(option)" [class.active]="selectedOption === option">
                {{ getTitle(option) }}
            </li>
        }
      </ul>
    </div>
  </div>


