import {Component, inject, input} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {combineLatest} from "rxjs";
import {finalize} from "rxjs/operators";
import {LoadingService} from "../../services/loading.service";
import {CurrencyInfo, LookupData} from "../../abstract/lookup";
import {ExchangeData} from "../../abstract/exchange";
import {commonModuleImports} from "../../app.imports";
import {ActivatedRoute, Router} from "@angular/router";
import {NumberWithCommasPipe} from "../../@theme/pipes";
import {MatDialog} from "@angular/material/dialog";
import {PopUpComponent} from "../pop-up/pop-up.component";

@Component({
  selector: 'app-connect-bot-page',
  standalone: true,
  imports: [...commonModuleImports, NumberWithCommasPipe],
  templateUrl: './connect-bot-page.component.html',
  styleUrl: './connect-bot-page.component.scss'
})
export class ConnectBotPageComponent {
  readonly dialog = inject(MatDialog);
  exchange = input.required<string>();
  selectedStrategy: number = 0;
  selectedCurrency?: CurrencyInfo;
  selectedPackage: number = 0;
  settings: any = {
    packages: [],
    baseCurrencies: [],
    strategies: [],
    exchanges: []
  };
  get selectedExchange() {
    return this.settings.exchanges[this.exchange()] || {};
  }

  get currencies() {
    if (this.settings && this.settings.currencies) {
      const res: CurrencyInfo[] = this.settings.currencies.filter((item: CurrencyInfo) => item.exchanges.indexOf(this.exchange()) !== -1);
      return res;
    }

    return new Array<CurrencyInfo>();
  }

  apiLabel: string = '';
  currentBalance: {[key: string]: number} = {};
  apiForm: FormGroup;
  form: FormGroup;
  currentStep = 0;
  isOauthFlow = false;
  blocks: HTMLElement[] = [];
  dropdownOpen = false;
  isValidKeys: Boolean = false;
  isValidAmount: Boolean = false;
  errorMessageApi?: string;
  isAuto: boolean = true;

  amount = '';

  constructor(
    formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private lookupService: LookupData,
    private exchangeService: ExchangeData,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {
    this.apiForm = formBuilder.group({
      'apiKey': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      'secretKey': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
      'passphrase': ['', [Validators.minLength(1), Validators.maxLength(250)]]
    });
    this.form = formBuilder.group({
      'packageId': [0, [Validators.required]],
      'strategyId': [0, [Validators.required]],
      'currencyId': [0, [Validators.required]],
      'exchange': ['', [Validators.required]],
      'amount': ['', [Validators.required, Validators.min(0)]],
      'comment': ['']
    });

    this.loadData();
    this.route.queryParams.subscribe(res => {
      if(res['hasError'] === 'true'){
        this.dialog.open(PopUpComponent, {
          data: {
            title: 'Cannot connect Binance Account',
            message: 'We cannot automatically create API key for your account. If API keys were previously created please delete it from your Binance account and try again.',
            cancelButtonText: 'Cancel'
          }
        });
        return;
      }
      if(res['auto'] === 'true') {
        this.currentStep = 2;
        this.isOauthFlow = true;
      }
    })
  }

  loadData(){
    const packages = this.lookupService.getPackages();
    const currencies = this.lookupService.getCurrencies();
    const strategies = this.lookupService.getStrategies();

    this.loadingService.openLoader();
    const ips = '20.223.178.5, 23.102.34.157, 20.223.157.56';

    combineLatest([packages, currencies, strategies])
      .pipe(finalize(() => this.loadingService.closeLoader()))
      .subscribe(res => {

        let filteredCurrencies = res[1][this.lookupService.defaultStrategy].filter(v => v.quote == null);
        filteredCurrencies.forEach(c => {
          c.exchanges = c.exchanges.map(e => e.toLowerCase());
        });

        this.settings = {
          packages: res[0],
          currencies: filteredCurrencies,
          strategies: res[2],
          exchanges: {
            'binance': {
              id: 'binance',
              name: 'Binance',
              src: '/assets/images/binance.svg',
              site: 'binance.com',
              clearPassphrase: true,
              allowOauth: true,
              description: `You are creating API keys manually. To automatically create API keys, click the "Auto API" button".`,
            },
            'kuna': {
              id: 'kuna',
              name: 'Kuna',
              src: '/assets/images/kuna.svg',
              site: 'kuna.io',
              apiLabel: 'Public Key',
              clearPassphrase: false,
              allowOauth: false,
              thirdInput: 'API Key',
              description: `Provide the selected API with “you can trade” permission. Also, add the following IP addresses to the whitelist: ${ips}.`,
            },
            'whitebit': {
              id: 'whitebit',
              name: 'WhiteBIT',
              src: '/assets/images/whitebit2.svg',
              site: 'whitebit.com',
              clearPassphrase: true,
              allowOauth: false,
              description: `Provide the selected API with “Order management”, “Wallet balance and history”, “Trade balance and order history” permissions. Also, add the following IP addresses to the whitelist: ${ips}.`,
            },
            'okx': {
              id: 'OKX',
              name: 'OKX',
              src: '/assets/images/okex.svg',
              site: 'okx.com',
              clearPassphrase: false,
              allowOauth: false,
              thirdInput: 'Passphrase',
              description: `Provide the selected API with “Order management”, “Wallet balance and history”, “Trade balance and order history” permissions. Also, add the following IP addresses to the whitelist: ${ips}.`,
            }
          }
        };

        this.selectedCurrency = this.settings.currencies[0];
        this.selectStrategy(this.lookupService.defaultStrategy);
        this.selectPackage(res[0][res[0].length - 1].id);
        if(this.isOauthFlow) {
          this.verifyApi();
        }
      });
  }

  ngOnInit(): void {
    this.blocks = Array.from(document.querySelectorAll('.block')); // Получаем все блоки
    this.updateBlockClasses();
  }

  startOAuthFlow() {
    this.exchangeService.getOauthLink(this.exchange()).subscribe(res => {
      window.location.href = res;
    })
  }

  setAuto(value: boolean): void {
    this.isAuto = value;
  }

  updateBlockClasses() {
    this.blocks.forEach((block, index) => {
      if (index === this.currentStep) {
        block.classList.add('active');
        block.classList.remove('done'); // Убираем "done" с активного блока
      } else {
        block.classList.remove('active');
        if (index < this.currentStep) {
          // Все предыдущие блоки отмечаем как "done"
          block.classList.add('done');
        } else {
          // Все будущие блоки неактивны и не отмечены как "done"
          block.classList.remove('done');
        }
      }
    });
  }

  // валидация
  isInputValid(): boolean {
    if (this.currentStep === 1) {
      return this.apiForm.valid;
    }
    return true;
  }

  isInputAmountValid(): boolean {
    if (this.currentStep === 2) {
      if(!this.selectedCurrency) return false;

      return this.form.value.amount >= this.selectedCurrency.minAmount
        && this.form.value.amount <= (this.currentBalance[this.selectedCurrency.base] || 0);
    }
    return true;
  }

  nextStep() {
    if(this.isInputValid()){
      this.errorMessageApi = undefined;
      if (this.currentStep < this.blocks.length - 1) {
        this.currentStep++;
        this.updateBlockClasses();
      }
    }
    else{
      this.errorMessageApi = 'Some error occurred';
    }
  }

  connectBot(){
    if(this.isInputAmountValid()){
      this.isValidAmount = false;
      this.submitSettings();
      if (this.currentStep < this.blocks.length) {
        this.currentStep++;
        this.updateBlockClasses();
      }
    }
    else{
      this.isValidAmount = true;
    }
  }

  // Нажатие на кнопку "Back"
  previousStep() {
    if (this.currentStep > 0 && !this.isOauthFlow) {
      this.currentStep--;
      this.updateBlockClasses();
    }
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  selectStrategy(strategyId: number): void {
    this.selectedStrategy = strategyId;
    this.form.controls['strategyId'].setValue(strategyId);
  }

  selectCurrencies(value: CurrencyInfo): void {
    this.selectedCurrency = value;
    this.dropdownOpen = false;
    this.form.controls['currencyId'].setValue(value?.id);
  }

  selectPackage(value: number): void {
    this.selectedPackage = value;
    this.form.controls['packageId'].setValue(value);
  }

  verifyApi() {
    if(!this.apiForm.valid && !this.isOauthFlow){
      this.errorMessageApi = "All fields are required";
      return;
    }

    this.loadingService.openLoader();
    const formValue = this.apiForm.value;

    const verify = this.exchangeService.verifyApiKeys(
      this.exchange(),
      this.isOauthFlow ? null : formValue.apiKey.trim(),
      this.isOauthFlow ? null : formValue.secretKey.trim(),
      this.isOauthFlow ? null : formValue.passphrase?.trim(),
      this.isOauthFlow
    );

    combineLatest([verify]).pipe(
      finalize(() => this.loadingService.closeLoader())
    ).subscribe(res => {
      const info = res[0];
      const errorMessage = info.status === 'KeyLengthError'
        ? 'Provided keys has invalid length'
        : info.status === 'KeyNotConnected'
          ? 'Provided keys cannot be used to connect to exchange.\nPlease verify that data is correct, all required permissions are provided and specified IPs in a whitelist.'
          : null;

      this.errorMessageApi = errorMessage || undefined;
      if (errorMessage != null) {
        this.dialog.open(PopUpComponent, {
          data: {
            title: 'Cannot verify API keys',
            message: errorMessage,
            cancelButtonText: 'OK'
          }
        });
        return;
      }

      this.currentBalance = info.balance;
      this.nextStep();
    });
  }

  setMaxAmount() {
    this.form.patchValue({
      amount: this.currentBalance[this.selectedCurrency!.base]?.toFixed(2) || 0
    });
  }

  submitSettings() {
    this.loadingService.openLoader();
    const formValue = {...this.form.getRawValue(), ...this.apiForm.getRawValue()};
    formValue['useOAuthData'] = this.isOauthFlow;

    let register;

    switch (this.exchange().toLowerCase()) {
      case 'binance':
        register = this.exchangeService.registerBinance(formValue);
        break;
      case 'kuna':
        register = this.exchangeService.registerKuna(formValue);
        break;
      case 'whitebit':
        register = this.exchangeService.registerWhiteBit(formValue);
        break;
      case 'okx':
        register = this.exchangeService.registerOkx(formValue);
        break;
    }

    combineLatest([register])
      .pipe(finalize(() => this.loadingService.closeLoader()))
      .subscribe(_ => {
        this.dialog.open(PopUpComponent, {
          data: {
            title: 'Congratulations!',
            message: 'The application to connect the bot to the ' + this.selectedExchange.name + ' exchange has been successfully submitted. \n\nAfter verifying the account compatibility by our staff, the bot will be connected. Stay tuned for further notifications.',
            cancelButtonText: 'OK'
          }
        }).afterClosed().subscribe(_ => {
          this.form.reset();
          this.router.navigate([`/my/${formValue.exchange}`], {queryParams: {'successConnect': true}});
        });
      });
  }
}
