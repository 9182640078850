import {RouterModule} from '@angular/router';
import {CarouselComponent} from "./common/carousel/carousel.component";
import {CommonModule} from "@angular/common";
import {CarouselModule} from "ngx-owl-carousel-o";
import {
  HeaderComponent,
  LinkComponent,
  ListComponent,
  ListItemComponent,
  OrderedListComponent, ParagraphComponent, SimpleSpanComponent,
  TextBlockComponent
} from "./common/text-block/text-block.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {PersonalAreaComponent} from "./common/personal-area/personal-area.component";
import { LoaderComponent } from './common/loader/loader.component';
import { RecentActionsTableComponent } from './common/recent-actions-table/recent-actions-table.component';
import {NgxEchartsDirective} from "ngx-echarts";
import { DropdownComponent } from './common/dropdown/dropdown.component';
import { PaginationComponent } from './common/pagination/pagination.component';
import { ConnectBotPageComponent } from './common/connect-bot-page/connect-bot-page.component';
import {NumberWithCommasPipe} from "./@theme/pipes";
import { PopUpComponent } from './common/pop-up/pop-up.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {StopActionPanelComponent} from "./common/stop-action-panel/stop-action-panel.component";
import {FilterComponent} from "./common/filter/filter.component";
import {SliderComponent} from "./common/slider/slider.component";
import { NgOptimizedImage } from '@angular/common';

export const commonModuleImports: ReadonlyArray<any> = [
  RouterModule,
  CommonModule,
  CarouselModule,
  FormsModule,
  MatToolbarModule,
  ReactiveFormsModule,
  MatSidenavModule,
  MatDialogModule,
  MatButtonModule,
  MatTooltipModule,
  NgOptimizedImage
];

export const commonComponentImports: ReadonlyArray<any> = [
  CarouselComponent,
  TextBlockComponent,
  StopActionPanelComponent,
  ListComponent,
  ListItemComponent,
  OrderedListComponent,
  ParagraphComponent,
  LinkComponent,
  SimpleSpanComponent,
  DropdownComponent,
  HeaderComponent,
  PopUpComponent,
  LoaderComponent,
  RecentActionsTableComponent,
  PersonalAreaComponent,
  NgxEchartsDirective,
  ConnectBotPageComponent,
  PaginationComponent,
  FilterComponent,
  SliderComponent,
  NumberWithCommasPipe
];

export const commonImports: ReadonlyArray<any> = [
  ...commonModuleImports,
  ...commonComponentImports
]
