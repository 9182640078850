import { Observable } from 'rxjs';

export interface ExchangeInfo{
    id: number;
    type: string;
    status: string;
    previewApiData: string;
    comment: string;
    name: string;
}

export interface ExchangeRegisterInfo {
    name: string;
    packageId: number;
    strategyId: number;
    currencyId: number;
    apiKey: string;
    secretKey: string;
    passPhrase: string;
    comment: string;
    useOAuthData: boolean;
}

export interface ExchangeConnectInfo {
  balance: {[key: string]: number};
  status: string;
  errorMessage: string;
}

export abstract class ExchangeData {
    abstract getRegisteredExchanges(): Observable<ExchangeInfo[]>;
    abstract getUserRegisteredExchanges(userId: any): Observable<ExchangeInfo[]>;
    abstract getBinanceExchange(): Observable<ExchangeInfo>;
    abstract getKunaExchange(): Observable<ExchangeInfo>;
    abstract getWhiteBitExchange(): Observable<ExchangeInfo>;
    abstract getOkxExchange(): Observable<ExchangeInfo>;
    abstract registerBinance(info: ExchangeRegisterInfo): Observable<any>;
    abstract registerKuna(info: ExchangeRegisterInfo): Observable<any>;
    abstract registerWhiteBit(info: ExchangeRegisterInfo): Observable<any>;
    abstract registerOkx(info: ExchangeRegisterInfo): Observable<any>;
    abstract deleteBinance(comment: string): Observable<any>;
    abstract deleteKuna(comment: string): Observable<any>;
    abstract deleteWhiteBit(comment: string): Observable<any>;
    abstract deleteOkx(comment: string): Observable<any>;
    abstract verifyApiKeys(exchange: string, api: string, secret: string, passphrase: string, useOAuthData: boolean): Observable<ExchangeConnectInfo>;
    abstract getOauthLink(exchange: string): Observable<string>;
}
