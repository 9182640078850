<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="side" [opened]="showSidebarMenu" class="example-sidenav"
               fixedInViewport="fixed" [fixedTopGap]="80" disableClose>
    <div class="container-menu">
      <ul class="top-menu">
        @for(item of menu; track item) {
          @if(hasAccess(item.role) | async) {
            <li
              [routerLink]="item.link"
              [class]="{ active: isActive(item.link) }"

              >
              <img [ngSrc]="item.icon" alt="item.icon" width="24" height="24"> {{item.name}}</li>
          }
        }
      </ul>
      <ul class="bottom">
        @for(item of bottomMenu; track item) {
          @if(hasAccess(item.role) | async) {
            <li [class]="{ active: isActive(item.link) }" (click)="item.action()"><img [ngSrc]="item.icon" alt="item.icon" width="24" height="24"> {{item.name}}</li>
          }
        }
      </ul>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>

  <ngx-footer></ngx-footer>
</mat-sidenav-container>
