import {
  AfterViewInit, ChangeDetectorRef,
  Component, input,
  OnInit,
  Type, viewChild,
  ViewContainerRef
} from '@angular/core';
import {HeaderItem, LinkChild, List, ListItem, ListItemChild, TextBlock, TextBlockItem} from '../../abstract/info';
import {commonModuleImports} from "../../app.imports";

export abstract class CommonDynamicComponent {

    public componentMapping: { [key: string]: Type<any> } = {
        'text': SimpleSpanComponent,
        'paragraph': ParagraphComponent,
        'list-unordered': ListComponent,
        'list-ordered': OrderedListComponent,
        'list-item': ListItemComponent,
        'link': LinkComponent,
        'heading': HeaderComponent
        // Add other mappings here
    };

    protected constructor(private cd: ChangeDetectorRef) {
    }

    public renderBlock(container: ViewContainerRef, blocks: any[]): void {
        container.clear();

        for (const block of blocks) {
            const componentRef = container.createComponent(this.getComponentType(block));
            componentRef.setInput('value', block);
        }
        this.cd.detectChanges();
    }

    getComponentType(block: any) {
        let key = block.type;

        if (block.format) {
            key += `-${block.format}`;
        }
        return this.componentMapping[key] || SimpleSpanComponent;
    }
}

@Component({
    selector: 'ngx-simple-span',
    styleUrls: ['./text-block.component.scss'],
    standalone: true,
    imports: [...commonModuleImports],
    template: `
      @if(isRegular) {
        <span [ngClass]="className">{{ value().text }}</span>
      } @else {
        <strong>{{ value().text }}</strong>
      }
    `,
})
export class SimpleSpanComponent {
    value = input.required<TextBlockItem>();

    get isRegular() {
        return !(this.value().bold ?? false);
    }

    get className() {
        if (this.value().italic) return 'exchange';

        return '';
    }
}

@Component({
  selector: 'ngx-simple-span',
  styleUrls: ['./text-block.component.scss'],
  standalone: true,
  imports: [...commonModuleImports],
  template: `<a [target]="target" [href]="value().url">{{ text }}</a>`,
})
export class LinkComponent {
    value = input.required<LinkChild>();

    get target() {
        return this.value().url.startsWith('http')
            ? '_blank'
            : '';
    }

    get text() {
        return this.value().children[0].text;
    }
}

@Component({
  selector: 'ngx-simple-span',
  styleUrls: ['./text-block.component.scss'],
  standalone: true,
  imports: [...commonModuleImports],
  template: `@switch (value().level){
    @case (1) {
      <h1>{{text}}</h1>
    }
    @case (2) {
      <h2>{{text}}</h2>
    }
    @case (3) {
      <h3>{{text}}</h3>
    }
    @case (4) {
      <h4>{{text}}</h4>
    }
    @case (5) {
      <h5>{{text}}</h5>
    }
    @case (6) {
      <h6>{{text}}</h6>
    }
  }`,
})
export class HeaderComponent {
    value = input.required<HeaderItem>();

    get text() {
        return this.value().children[0].text;
    }
}

@Component({
  selector: 'ngx-paragraph',
  standalone: true,
  imports: [...commonModuleImports],
  styleUrls: ['./text-block.component.scss'],
  template: `
      <p>
          <ng-template #placeToRender></ng-template>
          {{ text }}
      </p>`,
})
export class ParagraphComponent extends CommonDynamicComponent implements OnInit {
  value = input.required<TextBlock>();

  text = '';

  constructor(cd: ChangeDetectorRef) {
      super(cd);
  }

  placeToRender = viewChild.required('placeToRender', {read: ViewContainerRef});

  ngOnInit(): void {
    if (this.value().children.length === 1) {
      const item = this.value().children[0];

      if (item.type === 'text' && !item.italic) {
          this.text = item.text;
          return;
      }
    }

    super.renderBlock(this.placeToRender(), this.value().children);
  }
}


@Component({
  selector: 'ngx-list-block',
  standalone: true,
  imports: [...commonModuleImports],
  styleUrls: ['./text-block.component.scss'],
  template: `
    <ul>
        <ng-template #placeToRender></ng-template>
    </ul>
  `,
})
export class ListComponent extends CommonDynamicComponent implements OnInit {
  value = input.required<List>();

  constructor(cd: ChangeDetectorRef) {
      super(cd);
  }

  placeToRender = viewChild.required('placeToRender', {read: ViewContainerRef});

  ngOnInit(): void {
      super.renderBlock(this.placeToRender(), this.value().children);
  }
}

@Component({
  selector: 'ngx-o-list-block',
  standalone: true,
  imports: [...commonModuleImports],
  styleUrls: ['./text-block.component.scss'],
  template: `
      <ol>
          <ng-template #placeToRender></ng-template>
      </ol>
  `,
})
export class OrderedListComponent extends CommonDynamicComponent implements OnInit {
  value = input.required<List>();

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  placeToRender = viewChild.required('placeToRender', {read: ViewContainerRef});

  ngOnInit(): void {
    super.renderBlock(this.placeToRender(), this.value().children);
  }
}


@Component({
  selector: 'ngx-list-item',
  standalone: true,
  imports: [...commonModuleImports],
  styleUrls: ['./text-block.component.scss'],
  template: `
      <li>
          <div><ng-template #placeToRender></ng-template></div>
          {{ text }}
      </li>`,
})
export class ListItemComponent extends CommonDynamicComponent implements AfterViewInit {
  value = input.required<ListItem>();
  text = '';

  constructor(cd: ChangeDetectorRef) {
      super(cd);
  }

  placeToRender = viewChild.required('placeToRender', {read: ViewContainerRef});

  ngAfterViewInit(): void {
      if (this.value().children.length === 1) {
          const item = this.value().children[0];

          if (item.type === 'text' && !item.italic) {
              this.text = item.text;
              return;
          }
      }

      super.renderBlock(this.placeToRender(), this.value().children);
  }
}


@Component({
  selector: 'ngx-text-block',
  standalone: true,
  imports: [...commonModuleImports],
  styleUrls: ['./text-block.component.scss'],
  templateUrl: './text-block.component.html',
})
export class TextBlockComponent extends CommonDynamicComponent implements AfterViewInit {

  className = input<string>('');
  blocks = input.required<ListItemChild[]>();

  placeToRender = viewChild.required('placeToRender', {read: ViewContainerRef});

  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }

  ngAfterViewInit(): void {
    super.renderBlock(this.placeToRender(), this.blocks());
  }
}
