<div class="table-block">

    <table>
        <thead>
            <tr>
                <th>Date</th>
                <th>Pair</th>
                <th>Side</th>
                <th>Amount</th>
                <th>Price</th>
                <th>Total</th>
                @if(isAdmin()) {
                  <th>Action</th>
                }
              </tr>
        </thead>
        <tbody>

            @if(actions()!.length > 0){
              @for (item of actions(); track item; let i = $index) {
                <tr [ngClass]="{ 'even-row': i % 2 !== 0 }">
                  <th>
                    <div class="date-block">
                      <p class="date">{{ item.lastModifiedDate | date:'dd.MM.yyyy' }}</p>
                      <p class="time">{{ item.lastModifiedDate | date:'HH:mm:ss' }}</p>
                    </div>
                  </th>
                  <th>
                    <div class="pair-block">
                      <p class="name-pair">{{item.cryptoCoin}}/{{item.baseCoin}}</p>
                      <a target="_blank" href="https://www.binance.com/en/trade/{{item.symbol}}"><img ngSrc="/assets/images/copy.svg" width="16" height="16" alt="copy"></a>
                    </div>
                  </th>
                  <th [ngClass]="{ 'sell': item.side === 'SELL', 'buy': item.side === 'BUY' }">{{ item.side }}</th>
                  <th>{{ item.origQty % 1 === 0 ? item.origQty : (item.origQty | number: '1.2-2') }}</th>
                  <th>{{ item.itemPrice | number: '1.2-2'}}</th>
                  <th>{{ item.totalAmount | number: '1.2-2'}}</th>

                  @if(isAdmin()) {
                    <th [width]="30"><button (click)="open(item)" class="delete-btn"><img width="30px" height="30px" src="/assets/images/cross.png" alt="cross"></button></th>
                  }
                </tr>
              }
            } @else{
              <th class="no-data" colspan="6"><p>No Data</p></th>
            }

        </tbody>
    </table>

</div>
