import {Component, input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EntityBase, InfoData} from '../../abstract/info';
import {commonModuleImports} from "../../app.imports";
import {OwlOptions} from "ngx-owl-carousel-o";
import {LoadingService, ScreenSize} from "../../services/loading.service";

export interface CarouselItem {
  title: string;
  description: string;
  icon: string;
  slug: string;
  order?: string;
}

@Component({
  imports: [...commonModuleImports],
  selector: 'ngx-carousel',
  styleUrls: ['./carousel.component.scss'],
  templateUrl: './carousel.component.html',
  standalone: true
})
export class CarouselComponent<T> implements OnInit {
  color = input<string>();
  displayHeader = input(true);

  public activeIndex: number = 0;
  public currentUrl?: string;

  items?: CarouselItem[];

  header = input('Knowledge Base');

  baseLink = input('/knowledge-base');

  itemMap = input.required<CarouselItem>();

  entity = input.required<string>();

  moreLink = input('Learn More');

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly infoService: InfoData,
    private readonly loadingService: LoadingService
  ) {
    this.route.params.subscribe(_ => {
      if (this.items) this.refreshState();
    });
  }

  get itemSize() {
    return this.loadingService.screenSizeValue === ScreenSize.LargeDesktop
      ? 608
      : 346
  }

  ngOnInit(): void {
    this.infoService.getDataCollection<EntityBase<T>[]>(
      this.entity(),
      [],
      [],
      this.itemMap().order ? [this.itemMap().order!] : null,
      100000,
      1).subscribe(res => {
      this.items = res
        .data.map(i => {
          const attributes = i.attributes as any;

          return {
            title: attributes[this.itemMap().title],
            description: attributes[this.itemMap().description],
            icon: attributes[this.itemMap().icon],
            slug: attributes[this.itemMap().slug]
          };
        });

      this.refreshState();
    });
  }

  customOptions: OwlOptions = {
    loop: false,
    margin: 40,
    nav: true,
    dots: false,
    freeDrag: true,
    startPosition: 0,

    navText: ['', ''],
    autoWidth: true,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2,
        margin: 20,
      },
      1000: {
        items: 2.5,
        margin: 20
      },
      1440: {
        items: 3,
        margin: 40
      },
      1920: {
        items: 3,
        margin: 40
      }
    },
  };

  updateCarouselOptions(startPosition: number): void {
    this.customOptions = {
      ...this.customOptions,
      startPosition
    };
  }


  checkIndex() {
    if(!this.items) return 0;

    for (let i = 0; i < this.items.length; i++) {
      if (this.isActive(this.items[i].slug))
        return i;
    }

    return 0;
  }

  isActive(slug: string): boolean {
    return this.currentUrl?.endsWith(`/${slug}`) || false;
  }

  setActive(index: number): void {
    this.updateCarouselOptions(index);
  }

  refreshState(): void {
    this.currentUrl = this.router.url;
    this.activeIndex = this.checkIndex();
    this.updateCarouselOptions(this.activeIndex);
  }
}
