import { Component, input } from '@angular/core';
import {BotOrder, UserData} from '../../abstract/users';
import {commonModuleImports} from "../../app.imports";
import {MatDialog} from "@angular/material/dialog";
import {PopUpComponent} from "../pop-up/pop-up.component";
import moment from "moment";
import {LoadingService} from "../../services/loading.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-recent-actions',
  standalone: true,
  imports: [...commonModuleImports],
  templateUrl: './recent-actions-table.component.html',
  styleUrl: './recent-actions-table.component.scss'
})
export class RecentActionsTableComponent{

  isAdmin = input(false);
  count = input(0);

  actions = input<BotOrder[]>();

  constructor(private dialog: MatDialog, private loadingService: LoadingService, private userService: UserData) {
  }

  open(order: BotOrder) {
    this.dialog.open(PopUpComponent, {
      data: {
        title: 'Do you want to delete order?',
        message: `You are going to delete ${order.side} order.\nOrder created at ${moment(order.lastModifiedDate).format('DD.MM.YY HH:mm:ss')}.\n Amount: ${order.totalAmount}`,
        cancelButtonText: 'Cancel',
        saveButtonText: 'Delete',
      }
    }).afterClosed().subscribe(result => {
      if(result){
        this.loadingService.openLoader();
        this.userService.deleteHistoryOrdersById(order.id).pipe(
          finalize(() => this.loadingService.closeLoader())
        ).subscribe(_ => {
          alert('Deleted');
        });
      }
    })
  }
}
