import {Component, input, output} from '@angular/core';

@Component({
  selector: 'app-dropdown-slider',
  standalone: true,
  imports: [
  ],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss'
})
export class SliderComponent {
  currentIndex = 0;

  options = input<(string | {key:string, value: string})[]>([]);
  isPanel = input<boolean>();
  selected = output<string>();

  get selectedOption() : string | {key:string, value: string} {
    return this.options()[this.currentIndex];
  }

  getValue() {
    return typeof this.selectedOption === "string" ? this.selectedOption : this.selectedOption.value;
  }

  getTitle(val: string | {key:string, value: string}) {
    return typeof val === "string" ? val : val.key;
  }

  nextOption() {
    this.currentIndex = (this.currentIndex + 1) % this.options().length;
    this.selected.emit(this.getValue());
  }

  prevOption() {
    if(this.currentIndex == 0) {
      this.currentIndex = this.options().length;
    }
    this.currentIndex--;
    this.selected.emit(this.getValue());
  }
}
