<div class="pop-up">
    <div class="pop-up-inner">
        <h3>{{ title() }}</h3>

        <p>{{ message() }}</p>

        <div class="buttons">
            <button (click)="cancel()" class="box cancel">{{ cancelButtonText() }}</button>
            @if(saveButtonText()) {
              <button (click)="save()" class="box safe">{{ saveButtonText() }}</button>
            }
        </div>
    </div>
</div>
