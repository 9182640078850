import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpInterceptorFn} from "@angular/common/http";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "../../environments/environment";
import {Observable, of, ReplaySubject, switchMap} from "rxjs";
import {User} from "../abstract/users";
import {catchError, map} from "rxjs/operators";

export interface MenuItem {
  name: string,
  icon: string,
  role: string,
  link: string,
  class: string
};

@Injectable({
  providedIn: 'root',
})
export class UserAccessService {

  private readonly oidcSecurityService = inject(OidcSecurityService);
  currentUser = new ReplaySubject<User|null>(1);
  currentMenu = new ReplaySubject<MenuItem[]>(1);

  setUser(user: User|null) {
    this.currentUser.next(user);
  }

  setMenu(menu: MenuItem[]) {
    this.currentMenu.next(menu);
  }

  getUser() {
    return this.currentUser.asObservable();
  }

  getMenu() {
    return this.currentMenu.asObservable();
  }

  isInRole(role: string) : Observable<boolean> {
    if(role === 'guest') return of(true);

    return this.currentUser.pipe(
      map(u =>  (u && u.roles.indexOf(role) !== -1) || false)
    );
  }

  isRegistered() {
    return this.currentUser.pipe(
      map(u =>  u?.isRegistered || false)
    );
  }

  authorize() {
    this.oidcSecurityService.authorize()
      /*.subscribe(({ isAuthenticated, userData, accessToken, errorMessage }) => {
      if(isAuthenticated) {
        this.userService.getMyUser()
          .subscribe(res => {
            this.setUser(res);
            this.router.navigate(['/my']);
          });
      } else {
        this.setUser(null);
      }
    })*/
  }
}

export function accessTokenInterceptor(): HttpInterceptorFn {
  return (req, next) => {
    const authService = inject(OidcSecurityService);

    if (req.url.startsWith(environment.apiUrl)) {
      return authService.getIdToken().pipe(
        switchMap(token => {
          req = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + token),
          });

          return next(req).pipe(
            catchError((error) => {
              if(error instanceof HttpErrorResponse && error.status === 401) {
                authService.logoffLocal();
              }
              return of(error);
            })
          );
        })
      );
    }

    return next(req);
  }
}
