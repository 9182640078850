<div class="connect-bot">
    <div class="block login-bot">
        <div class="login-bot-inner">
            <div class="login-header">
              <h5>Create API keys on {{selectedExchange.name}}</h5>

              <div class="switcher" *ngIf="selectedExchange.allowOauth">
                <span
                  class="active"
                  [class.active]="isAuto"
                  (click)="setAuto(true)">Auto API</span>
                <span
                  [class.active]="!isAuto"
                  (click)="setAuto(false)">Manual Entry</span>
              </div>
            </div>

            <div class="text-inner">
                <!--<p>Provide the selected API with <span class="highlighted">Order management, Wallet balance and history, Trade balance and order history</span> permissions. Also add the following IP addresses to the whitelist: 20.223.178.5, 23.102.34.157, 20.223.157.56.</p>-->
                @if(isAuto && selectedExchange.allowOauth){
                  <p>Click Next and log in to your {{selectedExchange.name}} account. API keys for Burvix Traders will be created automatically. If you wish to create API keys manually, click Manual Entry.</p>
                } @else{
                  <p>{{selectedExchange.description}}</p>
                }

                <p>Please go to the <a routerLink="/knowledge-base/bot-launch">FAQ section</a> for detailed instructions on creating API keys.</p>

                <p>Facing issues with API connection? <a href="https://t.me/manimama_legal">Contact us via Telegram.</a></p>

            </div>
        </div>

        <div class="two-hundred">
            <p>DONE</p>
            <span>
                <img ngSrc="/assets/images/done.svg" alt="done" width="106" height="105">
            </span>
        </div>

        <div class="buttons">
            <button class="back">Back</button>
            @if(isAuto && selectedExchange.allowOauth){
              <button class="next" (click)="startOAuthFlow()">
                Next
                <img ngSrc="/assets/images/yellowArrow.svg" alt="yellowArrow" width="31" height="30">
              </button>
            } @else{
              <button class="next" (click)="nextStep()">
                Next
                <img ngSrc="/assets/images/yellowArrow.svg" alt="yellowArrow" width="31" height="30">
              </button>
            }
        </div>
    </div>

    <div class="block enter-api">
        <div class="login-bot-inner" [formGroup]="apiForm">
            <h5>Enter API and Secret Key</h5>

            <div class="text-inner">
                <p>Go back to the <a [href]="selectedExchange.site" target="_blank">{{selectedExchange.name}}</a>, copy both API keys and paste them into the fields below.</p>
            </div>

            <div class="inputs">
                <div class="input-api" [class]="{'error': errorMessageApi != undefined}">
                    <label for="apiKey-field">{{selectedExchange.apiLabel || 'API Key'}}</label>
                    <div class="input-error">
                        <input
                            formControlName="apiKey" required maxlength="250"
                            [placeholder]="'Enter the ' + (selectedExchange.apiLabel || 'API Key')"
                            type="text"
                            id="apiKey-field"
                            [class]="{'typed': apiForm.value.apiKey.trim()}"
                        >
                        <span>{{errorMessageApi}}</span>
                    </div>
                </div>

                <div class="input-secret" [class]="{'error': isValidKeys}">
                    <label for="secretKey-field">Secret Key</label>
                    <div class="input-error">
                        <input
                            formControlName="secretKey" required maxlength="250"
                            placeholder="Enter the Secret Key" type="text"
                            id="secretKey-field"
                            [class]="{'typed': apiForm.value.secretKey.trim()}"
                        >
                        <span>Something went wrong</span>
                    </div>
                </div>

                <div class="input-secret" [class]="{'error': isValidKeys}" *ngIf="selectedExchange.thirdInput">
                    <label for="passphrase-field">{{selectedExchange.thirdInput}}</label>
                    <div class="input-error">
                        <input
                            formControlName="passphrase" required maxlength="250"
                            id="passphrase-field"
                            [placeholder]="'Enter the ' + selectedExchange.thirdInput" type="text"
                            [class]="{'typed': apiForm.value.passphrase.trim()}"
                        >
                        <span>Something went wrong</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="two-hundred">
            <p>DONE</p>
            <span>
                <img ngSrc="/assets/images/done.svg" alt="done" width="106" height="105">
            </span>
        </div>

        <div class="buttons">
            <button class="back" (click)="previousStep()">Back</button>
            <button class="next" (click)="verifyApi()">
                Next
                <img ngSrc="/assets/images/yellowArrow.svg" alt="yellowArrow" width="31" height="30">
            </button>
        </div>
    </div>

    <div class="block amount" [formGroup]="form">
        <div class="login-bot-inner">
            <h5>Specify Coin and Amount to trade</h5>

            <div class="amount-inner">
                <div class="input-amount-block">

                    <div class="input-error" [class]="{'error': isValidAmount}">
                        <div class="input-amount">
                            <input
                              formControlName="amount" required type="number" [min]="0" autocomplete="off"
                              [class]="{'typed': form.value.amount > 0}"
                              placeholder="Enter the updated amount to start the bot..."
                            >
                            <button (click)="setMaxAmount()">MAX</button>
                        </div>

                        <span>Something went wrong</span>
                    </div>

                    <div class="dropdown">
                        <button class="dropdown-button" (click)="toggleDropdown()">
                          {{ selectedCurrency?.base }}
                          <div class="polygon-block">
                            <img class="polygon" ngSrc="/assets/images/Polygon 1.svg" alt="Polygon" width="10" height="6">
                          </div>
                        </button>
                        <div class="block-list" [class.show]="dropdownOpen">
                          <ul class="dropdown-list">
                            @for (option of currencies; track $index) {
                                <li (click)="selectCurrencies(option)" [class.active]="selectedCurrency === option">
                                    {{ option.base }}
                                </li>
                            }
                          </ul>
                        </div>
                    </div>
                </div>
                <p>We will only use the amount you specify for trading. <strong *ngIf="selectedCurrency && selectedCurrency.minAmount > 0">Min {{selectedCurrency.minAmount | ngxNumberWithCommas}} {{selectedCurrency.base}}.</strong></p>
                <p *ngIf="selectedCurrency && currentBalance">Available on account: {{(currentBalance[selectedCurrency.base] || 0) | ngxNumberWithCommas}} {{selectedCurrency.base}}.</p>
            </div>
        </div>

        <div class="two-hundred">
            <p>DONE</p>
            <span>
                <img ngSrc="/assets/images/done.svg" alt="done" width="106" height="105">
            </span>
        </div>

        <div class="buttons">
            <button class="back" (click)="previousStep()">Back</button>
            <button class="next" (click)="connectBot()">
                Connect a new bot
                <img ngSrc="/assets/images/yellowArrow.svg" alt="yellowArrow" width="31" height="30">
            </button>
        </div>
    </div>
</div>
