import {Component} from '@angular/core';
import {commonModuleImports} from "../../app.imports";

@Component({
  imports: [...commonModuleImports],
  selector: 'ngx-loader',
  styleUrls: ['./loader.component.scss'],
  templateUrl: './loader.component.html',
  standalone: true
})
export class LoaderComponent { }
