<div class="loader">
  <div class="burvix">
    <svg xmlns="http://www.w3.org/2000/svg" width="304" height="96" viewBox="0 0 304 96" fill="none">
      <rect class="rect5" x="16" y="16" width="32" height="32" fill="rgba(30, 30, 30, 1)"/>
      <rect class="rect4" x="16" y="48" width="32" height="32" fill="rgba(30, 30, 30, 1)"/>
      <rect class="rect3" x="48" y="48" width="32" height="32" fill="rgba(30, 30, 30, 1)"/>
      <rect class="rect2" x="48" y="16" width="32" height="32" fill="rgba(30, 30, 30, 1)"/>
      <rect class="rect1" x="16" y="16" width="32" height="32" fill="#E8B931"/>
      <path d="M36 79.4484V36H54.9835C58.3919 36 61.2447 36.4597 63.5417 37.379C65.8535 38.2983 67.5873 39.5853 68.7432 41.2401C69.9139 42.8949 70.4993 44.8113 70.4993 46.9894C70.4993 48.6442 70.1362 50.1221 69.4101 51.4233C68.6839 52.7104 67.6836 53.7782 66.4092 54.6268C65.1347 55.4754 63.6602 56.0694 61.9856 56.4089V56.8332C63.8232 56.918 65.52 57.3918 67.0761 58.2546C68.6469 59.1173 69.9065 60.3195 70.855 61.8611C71.8034 63.3886 72.2776 65.199 72.2776 67.2922C72.2776 69.6258 71.6552 71.712 70.4104 73.5506C69.1656 75.3751 67.365 76.8177 65.0088 77.8785C62.6525 78.9251 59.7924 79.4484 56.4284 79.4484H36ZM47.0033 70.9836H53.8054C56.1913 70.9836 57.9474 70.5522 59.0736 69.6895C60.2147 68.8267 60.7853 67.6246 60.7853 66.0829C60.7853 64.9656 60.5111 64.0039 59.9628 63.1977C59.4145 62.3774 58.6365 61.748 57.6288 61.3095C56.621 60.857 55.4133 60.6307 54.0054 60.6307H47.0033V70.9836ZM47.0033 53.8631H53.0941C54.2944 53.8631 55.3614 53.6721 56.295 53.2903C57.2286 52.9084 57.9548 52.3568 58.4735 51.6355C59.0069 50.9142 59.2737 50.0444 59.2737 49.026C59.2737 47.5693 58.7328 46.4237 57.651 45.5892C56.5692 44.7547 55.1095 44.3375 53.2719 44.3375H47.0033V53.8631Z" fill="white"/>
      <path d="M103.858 36H114.839V64.0251C114.839 67.2639 114.031 70.0855 112.416 72.4899C110.801 74.8801 108.548 76.7329 105.658 78.0482C102.769 79.3494 99.412 80 95.5887 80C91.7208 80 88.342 79.3494 85.4523 78.0482C82.5625 76.7329 80.3174 74.8801 78.7169 72.4899C77.1164 70.0855 76.3162 67.2639 76.3162 64.0251V36H87.3195V63.1128C87.3195 64.612 87.6603 65.9486 88.342 67.1225C89.0385 68.2964 90.0092 69.2157 91.254 69.8804C92.4988 70.5452 93.9437 70.8775 95.5887 70.8775C97.2336 70.8775 98.6711 70.5452 99.9011 69.8804C101.146 69.2157 102.117 68.2964 102.813 67.1225C103.51 65.9486 103.858 64.612 103.858 63.1128V36Z" fill="white"/>
      <path d="M120.484 79.4484V36H139.289C142.698 36 145.639 36.5869 148.114 37.7608C150.604 38.9206 152.523 40.5895 153.871 42.7676C155.22 44.9315 155.894 47.4986 155.894 50.4687C155.894 53.4812 155.205 56.0411 153.827 58.1485C152.449 60.2417 150.493 61.8399 147.958 62.9431C145.424 64.0321 142.423 64.5767 138.956 64.5767H127.063V56.3028H136.911C138.57 56.3028 139.956 56.0977 141.068 55.6876C142.194 55.2633 143.046 54.6268 143.624 53.7782C144.202 52.9155 144.491 51.8123 144.491 50.4687C144.491 49.125 144.202 48.0148 143.624 47.1379C143.046 46.2469 142.194 45.5821 141.068 45.1437C139.941 44.6911 138.556 44.4648 136.911 44.4648H131.487V79.4484H120.484ZM146.113 59.5911L157.45 79.4484H145.447L134.332 59.5911H146.113Z" fill="white"/>
      <path d="M169.669 36L179.85 67.8226H180.228L190.409 36H202.723L187.363 79.4484H172.714L157.354 36H169.669Z" fill="white"/>
      <path d="M217.376 36V79.4484H206.373V36H217.376Z" fill="white"/>
      <path d="M234.169 36L242.482 49.7261H242.838L251.241 36H263.555L249.818 57.7242L264 79.4484H251.374L242.838 65.5738H242.482L233.946 79.4484H221.409L235.525 57.7242L221.765 36H234.169Z" fill="white"/>
    </svg>
  </div>
</div>
