import {Component, EventEmitter, Input, Output} from '@angular/core';
import { commonImports } from '../../app.imports';

@Component({
  imports: [...commonImports],
  selector: 'ngx-pagination',
  styleUrls: ['./pagination.component.scss'],
  templateUrl: './pagination.component.html',
  standalone: true
})
export class PaginationComponent {

  @Input() pagesCount: number = 1;
  @Input() currentPage: number = 1;

  @Output() pageSelected = new EventEmitter<number>();

  get pages() {
    let p: string[] = ['1'];

    if (this.currentPage > 2) {
      p.push('...');
    }

    if (this.currentPage !== 1) p.push(this.currentPage.toString());

    if (this.currentPage + 1 < this.pagesCount) {
      p.push('...');
    }

    if(this.currentPage !== this.pagesCount) p.push(this.pagesCount.toString());

    return p;
  }

  selectPage(pageText: string) {
    if (pageText === '...') return;

    let page: number;
    page = parseInt(pageText, 10);
    this.pageSelected.emit(page);
    this.currentPage = page;
  }

  loadNextPage() {
    if (this.currentPage < this.pagesCount) {
      this.currentPage++;
    }

    this.selectPage(this.currentPage.toString());
  }

  loadPrevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }

    this.selectPage(this.currentPage.toString());
  }
}
