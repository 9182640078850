import {Component, inject, model} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

export interface PopupCommonInfo {
  title: string;
  message: string;
  cancelButtonText: string;
  saveButtonText: string;
}

@Component({
  selector: 'app-pop-up',
  standalone: true,
  imports: [],
  templateUrl: './pop-up.component.html',
  styleUrl: './pop-up.component.scss'
})
export class PopUpComponent {

  readonly dialogRef = inject(MatDialogRef<PopUpComponent>);
  readonly data = inject<PopupCommonInfo>(MAT_DIALOG_DATA);

  title = model(this.data.title);
  message = model(this.data.message);
  cancelButtonText = model(this.data.cancelButtonText);
  saveButtonText = model(this.data.saveButtonText);

  save() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close();
  }
}
